@use 'base';

$nav-width: 3rem;

nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
  height: $nav-width;
  background-color: base.$bg-4;
  backdrop-filter: blur(20px);
  z-index: 2;
  margin-top: 2rem;

  .angel {
    padding-left: 10%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2rem;
  }

  ul {
    display: flex;
    margin: 0;
    padding-right: 10%;

    li:hover {
      background-color: base.$accent-4;
    }

    button {
      border: none;
      padding: 1rem;
      background: none;
      color: base.$text-color;
      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      font-weight: 400;
    }

    a {
      display: block;
      text-decoration: none;
      padding: 1rem;
      color: base.$text-color;
      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      font-weight: 400;
      box-sizing: border-box;
    }
  }
}


.selected {
  border-bottom: 2px solid base.$accent;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    margin: auto;
    border: none;
    background: none;
    font-size: 1.5rem;
    display: flex;
    color: base.$text-color;
  }

  nav {
    margin-top: 0;
  }

  nav .angel {
    padding-left: 1rem;
  }

  nav ul {
    display: none;
    position: absolute;
    top: $nav-width;
    background-color: base.$bg-4;
    backdrop-filter: blur(20px);
    flex-direction: column;
    width: 100%;
    height: calc(100vh - $nav-width);
    padding: 0;

    button,
    a {
      width: 95%;
      width: calc(100vw - 4px);
      padding: 1rem;
      text-align: start;
    }

  }

  .selected {
    border: none;
    border-right: 4px solid base.$accent;
    background-color: base.$accent-4;
  }

  nav ul.open {
    display: block;
  }
}