$card: rgb(237, 242, 247);
$accent: hsl(215deg, 90%, 55%);
$accent-2: hsl(215deg, 90%, 60%);
$accent-3: hsl(215deg, 90%, 95%);
$accent-4: rgba(158, 196, 250, 0.5);
$accent-5: hsl(215deg, 90%, 80%);
$pad: 2rem;
$pad-lg: 4rem;
$text-color: #222;
$text-2: #777;
$gray: #ebebeb;
$gray-2: #f5f6f7;
$gray-3: #dedede;
$bg: #fefefe;
$bg-2: #efefef;
$bg-3: hsl(35deg, 50%, 95%);
$bg-4: rgba(249, 243, 236, 0.8);
$radius: 3px;