@use 'base';

.contact-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: base.$accent;
  text-align: center;
  padding-bottom: base.$pad-lg;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.contact-content a {
  text-decoration: none;
  color: inherit;
}

.call-to-action {
  font-weight: 400;
  color: base.$text-color;
}

@media (min-width: 768px) {
  .contact-section {
    margin-top: 0;
    padding: 0;
    background-color: base.$gray;

    h2 {
      padding: 0;
      margin: 0;
      width: 80%;
      margin: 0 auto;
    }
  }

  .contact-content {
    font-size: 1.25rem;
  }
}