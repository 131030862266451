@use 'base';

.project-card {
  width: 450px;
  padding: 1.5em;
  font-family: Roboto, Arial, sans-serif;
  font-family: 'Inter', sans-serif;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow:
    0px 0.5px 3.6px rgba(0, 0, 0, 0.024),
    0px 1.3px 10px rgba(0, 0, 0, 0.035),
    0px 3px 24.1px rgba(0, 0, 0, 0.046),
    0px 10px 80px rgba(0, 0, 0, 0.07);

  .project-name {
    text-transform: capitalize;
    font-size: 1.5em;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .description {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 1.25rem;
    font-size: 1rem;
  }

  .tech-container {
    margin-top: 0;
    justify-content: center;
    list-style: none;
    display: flex;
    width: 100%;
    color: base.$text-2;
    font-size: .9rem;

    .tag-icon-container {
      display: flex;
      align-items: center;
      margin-right: .5rem;

      .tag {
        font-size: 1.1rem;
      }
    }

    .tech {
      display: flex;
      align-items: center;

      li {
        padding: .5em 0;
        margin-right: .5rem;
      }
    }
  }

  .project-links {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    a {
      color: base.$text-color;
    }

    .svg-and-text {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      text-decoration: none;
      cursor: pointer;
      background: inherit;
      border-radius: 3px;
      padding: .75rem 0;
      width: 11rem;
      background-color: base.$accent;
      color: white;
      font-weight: 600;
      box-sizing: border-box;
    }

    .small-svg {
      margin-right: .5em;
      padding: 0;
      width: 1.2em;
      height: 1.2em;
    }

  }
}

@media (max-width: 768px) {
  .project-card {
    .tech-container {
      .tech {
        font-size: .7rem;
        flex-wrap: wrap;
      }
    }

    .project-links {
      .svg-and-text {
        width: 30%;
      }

      .small-svg {
        margin-right: 0;
      }

      .link-text {
        display: none;
      }

    }
  }
}