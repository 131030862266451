@use 'base';

body {
  margin: 0;
  font-size: 16px;
  color: base.$text-color;
  background-color: base.$bg-3;
}

@media (min-width: 768px) {
  body {
    padding: 0;
  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 2.5em;
}

ul {
  list-style: none;
  padding: 0;
}

.closed {
  display: none !important;
}

.about {
  padding-left: 1rem;
  width: 235px;
  font-family: 'Inter', sans-serif;

  h3 {
    margin: 0;
    text-align: center;
    color: base.$accent;
    font-weight: 600;
  }

  p {
    margin: 0;
    text-align: center;
  }
}

.projects {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .about-text {
    margin-bottom: base.$pad;
  }
}

@media (min-width: 768px) {
  .about {
    padding-left: 10%;
  }
}